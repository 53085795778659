import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UserStorageService } from '../login/user-storage.service';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from '../login/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MatToolbarModule, MatButtonModule, MatIconModule, MatMenuModule],
  template: `
    <mat-toolbar>
      <span class="spacer"></span>
      @if (user) {
        <button mat-button [matMenuTriggerFor]="userMenu">
          <span>{{ user.matricule }}</span>
          <mat-icon iconPositionEnd>account_circle</mat-icon>
        </button>
      }
    </mat-toolbar>
    <mat-menu #userMenu="matMenu">
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Déconnexion</span>
      </button>
    </mat-menu>
  `,
  styles: `
    .spacer {
      flex: 1 1 auto;
    }`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  private authService = inject(AuthService);
  private userStorageService = inject(UserStorageService);
  private router = inject(Router);
  
  user = this.userStorageService.getUser();

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
