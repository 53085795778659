// auth.service.ts
import { User } from '../models/user.model';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { UserStorageService } from './user-storage.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private httpClient = inject(HttpClient);
  private userStorageService = inject(UserStorageService);

  login(matricule: string): Observable<User> {
    return this.httpClient.post<User>(`${environment.apiUrl}/user/authenticate`, { matricule })
      .pipe(tap(response => this.userStorageService.saveUser(response)));
  }

  async isAuthenticated() {
    return this.userStorageService.getUser() !== null;
  }

  logout() {
    this.userStorageService.removeUser();
  }
}
